
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconChevronRight } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconChevronRight,
  },
})
export default class AboutUs extends Vue {
  @Prop() siteName!: string;
  @Prop() logo!: string;
  @Prop() claim!: string;
  @Prop({ default: "Istituto Vendite Giudiziarie" }) prefix!: string;
  @Prop() aboutUsText: any;
  @Prop() isassociato!: boolean;
}

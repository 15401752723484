import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=05103deb&scoped=true"
import script from "./AboutUs.vue?vue&type=script&lang=ts"
export * from "./AboutUs.vue?vue&type=script&lang=ts"
import style0 from "./AboutUs.vue?vue&type=style&index=0&id=05103deb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05103deb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeAboutTheBox: require('/usr/src/nuxt-app/components/home/about/TheBox.vue').default})
